export const Colors = {
  primary: {
    BLACK: "#242424",
    BLACK_2: "#575757",
    BLACK_3: "#8A8A8A",
    BLACK_4: "#A8A8A8",
    BLACK_5: "#D6D6D6",
    BLACK_6: "#F5F5F5",
    WHITE: "#FFFFFF",
    WHITE_2: "rgba(255, 255, 255, 0.8)",
    WHITE_3: "rgba(255, 255, 255, 0.64)",
    WHITE_4: "rgba(255, 255, 255, 0.48)",
    WHITE_5: "rgba(255, 255, 255, 0.32)",
    WHITE_6: "rgba(255, 255, 255, 0.16)",
    BLUE: "#0062FF",
    BLUE_2: "#3378FF",
    BLUE_3: "#669AFF",
    BLUE_4: "#99BCFF",
    BLUE_5: "#CCDDFF",
    BLUE_6: "#E9F0FF",
  },
  text: {
    SUCCESS: "#43C82E",
  },
  accent: {
    GREEN: "#81B440",
    YELLOW: "#DE9E3C",
    CYAN: "#4FBAA7",
    PURPLE: "#8163D7",
    RED: "#DE684E",
    RED_2: "#DE7A64",
    RED_3: "#DE8C7A",
    RED_4: "#DE9E90",
    RED_5: "#DEB0A6",
  },
};
